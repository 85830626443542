import Image from "next/image";
import Link from "next/link";

interface ArticlesProps {
  image: string;
  link: string;
  title: string;
  description?: string;
}

const Articles: React.FC<ArticlesProps> = (props) => {
  const { image, link, title, description } = props;
  return (
    <div className="flex pt-2">
      <Image
        src={image}
        alt="image"
        width={96}
        height={64}
        loading="lazy"
        className="h-full rounded-md shadow"
      />
      <div className="ms-3 self-center">
        <Link href={link} className="font-semibold hover:text-ored-600">
          {title}
        </Link>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
    </div>
  );
};

export default Articles;
