import Jumbotron from "./Jumbotron";
import Section from "./Section";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SidebarLayanan from "./SidebarLayanan";
import Form from "./Form";

const Layouts = {
  Jumbotron,
  Section,
  Navbar,
  Footer,
  SidebarLayanan,
  Form,
};

export default Layouts;
