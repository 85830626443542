import Image from "next/image";

interface DetailArticlesProps {
  image: string;
  alt: string;
  content: React.ReactNode;
  title?: string;
}

const DetailArticles: React.FC<DetailArticlesProps> = (props) => {
  const { image, alt, content, title } = props;
  return (
    <>
      <Image
        src={image}
        alt={alt}
        width={100}
        height={100}
        className="w-full"
        loading="lazy"
      />
      <div className="mt-6">
        <h2 className="lg:text-4xl text-xl text-center font-bold">{title}</h2>
        <p className="text-slate-400 p-3">{content}</p>
      </div>
    </>
  );
};

export default DetailArticles;
